[data-bs-theme="dark"] {
  // variables
  .nav {
    --bs-nav-link-color: #fff;
    --bs-nav-link-hover-color: #666666;
  }
  // style
  body {
    --bs-body: #000;
    --bs-body-bg: #000;
    --bs-main: #fff;
    --bs-heading-color: #fff;
    --bs-neutral: #666666;
    --bs-bg-inbox: #141414;
    --bs-second: #171717;
    --bs-third: #2E2E2E;

    --bs-bot-first: #242424;
    --bs-bot-second: #404040;
    --bs-bot-third: #9B9B9B;

    --bs-logo-primary: #00008B;
    --bs-logo-secondary: #0000B9;
    --bs-logo-third: #0000E7;
    --bs-logo-fourth: #1616FF;

    --bs-basic-logo-primary: #AEAEAE;
    --bs-basic-logo-second: #C5C5C5;
  }
   h1,h2,h3,h4,h5,span,label {
    color: #fff;
  }
  .btn-primary {
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #00008B;
  }
  .carousel-caption > h1,
  .carousel-caption > h3 {
    color: var(--bs-main);
  }
  .hover-stroke:hover > svg > path {
    stroke: var(--bs-body);
  }
  .hover-fill:hover > svg > path {
    fill: var(--bs-body);
  }
  .hover-burger:hover > div > span {
    background-color: var(--bs-body);
  }
  .light {
    display: none !important;
  }
}
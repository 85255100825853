[data-bs-theme="light"] {
  // variables
  .nav {
    --bs-nav-link-color: #000;
    --bs-nav-link-hover-color: #545454;
  }

  .btn-outline-light {
    --bs-btn-bg: #fff;
    --bs-btn-color: #000;
    --bs-btn-border-color: #000; 

    --bs-btn-hover-bg: #000;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-border-color: #fff;
  }
  .btn-primary {
    --bs-btn-hover-color: #fff !important;
  }
  // style 
  body {
    --bs-body: #fff;
    --bs-main: #000;
    --bs-heading-color: #000;
    --bs-neutral: #9F9F9F;
    --bs-bg-inbox: #F6F6F6;
    --bs-second: #E8E8E8;
    --bs-third: #D1D1D1;

    --bs-bot-first: #DBDBDB;
    --bs-bot-second: #C7C7C7;
    --bs-bot-third: #9B9B9B;

    --bs-logo-primary: #00008B;
    --bs-logo-secondary: #0000B9;
    --bs-logo-third: #0000E7;
    --bs-logo-fourth: #1616FF;

    --bs-basic-logo-primary: #3B3B3B;
    --bs-basic-logo-second: #525252;
  }
  h1,h2,h3,h4,h5,span,label {
    color: #000;
  }
  .btn-primary {
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #00008B;
  }
  .hover-stroke:hover > svg > path {
    stroke: var(--bs-body);
  }
  .hover-fill:hover > svg > path {
    fill: var(--bs-body);
  }
  .hover-burger:hover > div > span {
    background-color: var(--bs-body);
  }
  .dark {
    display: none !important;
  }
}
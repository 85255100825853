// general
:root {
  --bs-body-font-family: "Manrope", sans-serif !important;
  --bs-body-font-weight: 700;
}

h1 {
  letter-spacing: -.03em;
}
h2 {
  letter-spacing: -.02em;
}

html,
body {
  overflow-y: scroll;
}

.pricing-lh {
  line-height: 1.7;
}

input[type="checkbox"],
input[type="checkbox"]:focus,
input[type="checkbox"]:active,
input[type="checkbox"]::after,
input[type="checkbox"]:checked,
input[type="checkbox"]:checked::after,
input[type="checkbox"]:focus-visible,
input[type="checkbox"]:focus:not(:focus-visible) {
  outline: none !important;
  box-shadow: none !important;
}
input[type="checkbox"] {
  background-color: #fff;
}
input[type="checkbox"]:checked {
  background-color: #000;
}

input[type="checkbox"].pro-checkbox-main,
input[type="checkbox"].pro-checkbox {
  border: 1px solid var(--bs-main) !important;
}

.divider {
  opacity: 1;
}
.divider-bot-first {
  border-top: 1px var(--bs-bot-first) solid;
}
.divider-body {
  border-top: var(--bs-body) solid;
}

.low-container {
  width: 1080px !important;
}

// h1,
// .btn,
// .fw-lighter,
// .fw-ultralight {
  
// }

header {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9000;
  background-color: var(--bs-body);
}
main {
  margin-top: 88px;
}

h1 {
  line-height: 1.4 !important;
}
a {
  text-decoration: none !important;
}
.w-90 {
  width: 90% !important;
}
.h-15 {
  height: 15% !important;
}
.letter-zero {
  letter-spacing: 0;
}
.blur {
  filter: blur(2px);
}

.animated-icon {
  width: 20px;
  height: 15px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.animated-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  background: var(--bs-main);
}

.animated-icon span:nth-child(1) {
  top: 0px;
}

.animated-icon span:nth-child(2),
.animated-icon span:nth-child(3) {
  top: 7.5px;
}

.animated-icon span:nth-child(4) {
  top: 15px;
}

.animated-icon.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.animated-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.animated-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.burger-open > div {
  display: flex;
  position: absolute;
  top: 86px;
  left: 0;
  right: 0;
  flex-direction: column;
  background-color: var(--bs-body);
  transition: .3s;
}

.nav-link:hover {
  color: var(--bs-neutral);
}

.nav-link,
.nav-link:focus {
  color: var(--bs-main);
}

.text-neutral {
  color: var(--bs-neutral);
}
.text-main {
  color: var(--bs-main);
}

.fw-ultrabold {
  font-weight: 900 !important;
}
.fw-ultralight {
  font-weight: 100 !important;
}

.carousel .carousel-indicators [data-bs-target] {
  color: var(--bs-main) !important;
  width: 40px !important;
  height: 5px !important;
  background-color: #fff !important;
}

.carousel-caption {
  left: 10% !important;
  right: 10% !important;
  bottom: 50%;
  transform: translateY(40%);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem !important;
  height: 3rem !important;
  filter: none !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") !important;
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.carousel-picture {
  height: inherit;
}

.settings {
  width: 50px;
  height: 50px;
}

.text-gold:hover {
  color: #A16A00 !important;
}
.bg-gold:hover {
  transition: .3s;
  opacity: .5 !important;
}
.border-gold {
  border: 1px solid var(--bs-gold);
}

.phalf-3 {
  padding: .75rem !important;
}
.mt-10 {
  margin-top: 10rem;
}
.my-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.py-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.pyhalf-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.pyhalf-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - 30px));
  }
}

.marquee__wrapper {
  display: flex;
  overflow: hidden;
  mask-image: linear-gradient(90deg, transparent, #000 20%, #000 80%, transparent);
}

.marquee {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  -webkit-animation: marquee 80s infinite linear;
  -moz-animation: marquee 80s infinite linear;
  animation: marquee 80s infinite linear;
}

.marquee > svg {
  display: flex;
  margin: 30px;
}

.advantage_section {
  gap: 20px;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 0px .5rem .5rem;
  border-top: 3px solid var(--bs-gold);
  background-color: var(--bs-bg-inbox);
}

.generators_section,
.prices_footer {
  display: block;
  position: relative;
  background-color: var(--bs-bg-inbox);
}

.prices_buttons {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.generator_wordpress > img {
  display: block;
  position: absolute;
  width: 650px;
  height: 420px;
  right: 0;
  bottom: 0;
}
// .generator_safepage > img {
//   width: 500px;
// }

.generator_headline {
  background-color: var(--bs-main);
}
.generator_headline > span {
  color: var(--bs-body) !important;
}
.tape__wrapper {
  display: flex;
  position: relative;
  // right: 0;
}
.tape_pic {
  display: flex;
  position: absolute;
  right: 0;
}
.tape {
  display: flex;
  position: absolute;
  top: 37px;
  right: 0px;
  transform: rotate(45deg);
  font-size: .8rem;
}

.inherit {
  background-color: inherit;
}

.faq > div > a {
  text-decoration: none;
}
.faq_question {
  border-bottom: 1px solid var(--bs-bg-inbox);
}
.generator_option {
  border-bottom: 1px solid var(--bs-bot-first);
}
.card {
  padding: 0 !important;
  border: none !important;
}
.card-generator {
  background-color: inherit; 
}
.faq_item > p > svg {
  transition: transform .3s ease-in-out;
}
.faq_item > p > button {
  transition: border .3s ease-in-out !important;
}
.faq_item > p > button {
  // border-bottom: 1px solid var(--bs-body);
  // border-radius: 0 !important;
}
.faq_item > p:hover > svg {
  transform: rotate(-90deg);
}
.faq_item > p > button:active,
.faq_item > p > button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.faq_item > p > button {
  --bs-btn-box-shadow: none !important;
  --bs-btn-border-color: none !important;
}
// .btn-dropdown:active + svg {
// .btn-dropdown:focus + svg {
  // transform: rotate(-90deg);
// }

.generator_item > p > svg {
  transition: transform .3s ease-in-out;
}
.generator_item > p > button {
  transition: border .3s ease-in-out !important;
}
.generator_item > p > button {
  // border-bottom: 1px solid var(--bs-body);
  // border-radius: 0 !important;
}
.generator_item > p:hover > svg {
  transform: rotate(-90deg);
}
.generator_item > p > button:active,
.generator_item > p > button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.generator_item > p > button {
  --bs-btn-box-shadow: none !important;
  --bs-btn-border-color: none !important;
}

.telegram_item {
  background-color: var(--bs-bg-inbox);
}
.telegram_inbox {
  height: 170px;
  background-color: var(--bs-bot-first);
}

.bot_btn {
  background-color: var(--bs-bot-second);
}

.telegram_headline > span {
  color: var(--bs-bot-third) !important;
}

.footer > div > span,
.footer > div > div > span {
  font-weight: 300;
}

// AUTH

.auth-wrapper {
  margin-top: 5rem;
}
.auth-container {
  height: 80vh;
}
.auth-footer {
  position: absolute;
  bottom: 1rem;
}

.radio_group {
  background-color: var(--bs-bg-inbox);
}

input::placeholder,
textarea::placeholder, {
  color: var(--bs-neutral) !important;
}
.input-group > input {
  color: var(--bs-main) !important;
  border: 1px solid var(--bs-neutral) !important;
}
input:focus {
  box-shadow: none !important;
  outline: 1px solid var(--bs-main) !important;
}
form > a {
  text-decoration: none;
}
.form_group > form {
  display: none;
}
.active {
  display: flex !important;
}
.sign-in > label,
.sign-up > label {
  border: none !important;
  box-shadow: none !important;
}
.sign-in > label:hover,
.sign-up > label:hover {
  background: var(--bs-body);
}
.show > label {
  background-color: var(--bs-body);
}

// GENERATORS

.infobar > div {
  background-color: var(--bs-bg-inbox);
}

.infobar_item {
  // background-color: black !important;
  // box-shadow: 5px 5px 5px gray;
}

.tools {
  display: none !important;
  background-color: var(--bs-bg-inbox);
}

.form-select {
  border: 1px solid var(--bs-main);
  background-color: inherit;
}

.set-up-addon > input, textarea {
  background-image: none !important;
}

.generator-field > input,
.generator-field > textarea,
.generator-field > select {
  border: 1px solid var(--bs-neutral);
  background-color: inherit;
}
.generator-nonarrow > input,
.generator-nonarrow > textarea,
.generator-nonarrow > select, {
  background-image: none !important;
}

.preview {
  background-color: var(--bs-bg-inbox);
}

.generator-description {
  color: var(--bs-neutral) !important;
}

// INFO FOOTER
.information > a {
  color: var(--bs-main);
}

// ADSPECT
.non-cloaking__wrapper {
  text-decoration: none;
}
.non-cloaking {
  border: 1px dashed var(--bs-neutral);
}
.non-cloaking > span {
  color: var(--bs-neutral) !important;
}

// CHECKOUT

.checkout__wrapper {
  background-color: var(--bs-bg-inbox);
  width: 50% !important;
}
.checkout {
  cursor: pointer;
}
.checkout:hover {
  
}
.checkout > div.col {
  color: var(--bs-main);
}
.checkout-selected {
  transition: .3s;
  background-color: var(--bs-gold);
}
.checkout-selected > div.col {
  color: var(--bs-body);
}

.generation_load > i {
  position: absolute;
  margin: auto 10px;
  width: 32px;
  height: 32px;
  background: none !important;
  animation: comsign-spinner infinite 1.5s linear;
  border-radius: 100%;
  border-style: solid;
  border-width: 4px;
  border-color: transparent var(--bs-main) transparent var(--bs-main);
}

.generation-pro-btn {
  width: 110px;
}

@keyframes comsign-spinner {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  25% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  50% {
    transform: rotate(1800deg);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  75% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(0deg);
  }
}

.partner > a {
  height: 100px;
}

.partner > a > img {
  max-width: 100px;
  max-height: 60px;
}

.telegram_support {
  position: fixed;
  right: 2%;
  bottom: 4%;
  z-index: 9999;
}

.bonus-logo {
  height: 100px;
}
.bonus-line {
  background-color: var(--bs-bg-inbox);
}
.bonus-description > div {
  color: var(--bs-main);
}

.bonus-logo > img {
  max-width: 180px;
  max-height: 100px;
}

.non-hover-gold:hover {
  color: var(--bs-gold) !important;
}

.svg-gold:hover > path {
  fill: #A16A00 !important;
}

@media (min-width: 576px) {
  .adspect_description {
    width: 50% !important;
  }
  .auth_btns {
    width: auto !important;
  }
}

@media (max-width: 576px) {
  .low-container {
    width: 100% !important;
  }
  .scroll-container {
    width: 1080px;
  }
  .scroll-overflow {
    overflow-x: scroll;
  }
  #profile-button {
    display: none;
  }
  .telegram_support {
    position: fixed;
    right: 4%;
    bottom: 3%;
    z-index: 9999;
  }
  .checkout__wrapper {
    width: 100% !important;
  }
  .generators_infobar {
    border-top: .1px solid var(--bs-neutral) !important;
  }

  .adspect_description,
  .no_answer > a {
    width: 100% !important;
  }
  .nav-link:hover {
    color: var(--bs-main) !important;
  }
  h1 {
    font-size: calc(1.3rem + .5vw) !important;
  }
  h3 {
    font-size: 1rem !important;
  }
  span {
    font-size: .85rem !important;
  }
  .carousel-item {
    height: 450px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
  .carousel-caption {
    text-align: left;
    transform: translateY(45%);
  }
  .carousel-main {
    width: 160px;
    height: 40px;
  }
  .carousel-gp {
    width: 160px;
    height: 32px;
  }
  .carousel-adspect {
    width: 160px;
    height: 35px;
  }
  .prices-adspect {
    width: 264px;
  }
  .mt-10 {
    margin-top: 5rem !important;
  }
  .ms-10 {
    margin-left: 5rem !important;
  }
  .my-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .py-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .generator_wordpress > img {
    position: relative;
    width: 90%;
    height: 200px;
  }
  .btn-dropdown {
    width: 90% !important;
  }
}